<template>
  <div class="px-8 mt-4">
    <v-dialog
      v-model="prViewItemDialog"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="prModal"
      scrollable
    >
      <v-card>
        <v-toolbar max-height="65" dark color="green darken-2">
          <v-toolbar-title>{{
            prData.isDBM == 0 && prData.isApproved
              ? prData.PRCODE
              : prData.PRCODETemp
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-btn outlined @click="viewRemarks()" class="mr-5"><v-icon class="mr-2">mdi-email</v-icon>Messages</v-btn> -->
          <v-btn icon dark @click="clickCloseMainDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div>
          <v-stepper alt-labels>
            <v-stepper-header>
              <v-stepper-step
                color="green"
                step="1"
                class="pa-3 pl-md-5"
                :complete="prData.FinanceStatusID == 7"
              >
              <span class="text-no-wrap">Funds Availability</span>
              <small class="text-no-wrap font-size-12 font-weight-bold">{{formatDateTime(prData.FinaceApprovedDate)}}</small>
              </v-stepper-step>

              <v-divider class="my-6"></v-divider>

              <v-stepper-step
                color="green"
                :complete="prData.PresidentStatusID == 7"
                step="2"
                class="pa-3"
              >
                <span class="text-no-wrap">College President Approval</span>
                <small class="text-no-wrap font-size-12 font-weight-bold">{{formatDateTime(prData.PresidentApprovedDate)}}</small>
              </v-stepper-step>

              <v-divider class="my-6" v-if="prData.isDBM == 0"></v-divider>

              <v-stepper-step
                color="green"
                :complete="prData.PSUStatusID == 7"
                step="3"
                class="pa-3 pr-md-5"
                v-if="prData.isDBM == 0"
              >
                <span class="text-no-wrap">PSU Verification</span>
                <small class="text-no-wrap font-size-12 font-weight-bold">{{formatDateTime(prData.PSUApprovedDate)}}</small>
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </div>
        <v-alert
          border="left"
          type="error"
          v-if="prData.isPending"
          prominent
          dense
          class="white--text amber accent-4"
        >
          <div v-if="prData.FinanceRemarks"> <strong>Finance:</strong> {{ prData.FinanceRemarks }}</div>
          <div v-if="prData.PresidentRemarks"> <strong>College President:</strong> {{ prData.PresidentRemarks }}</div>
          <div v-if="prData.PSURemarks"> <strong>PSU:</strong> {{ prData.PSURemarks }}</div>
        </v-alert>

        <v-alert
          border="left"
          type="error"
          v-if="prData.PSUStatusID == 6"
          prominent
          dense
          class="white--text amber accent-4"
        >
          <div v-if="prData.PSURemarks"> <strong>PSU:</strong> {{ prData.PSURemarks }}</div>
        </v-alert>

        <!-----Header----->
        <v-card-text style="max-height: 2000px;">
        <div>
          <v-row class="mt-4">
            <v-col cols="12" class="d-flex justify-center">
              <v-subheader class="font-weight-bold text-h6"
                >Purchase Request</v-subheader
              >
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-5">
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Entity Name:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>Davao del Norte State College</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Fund Cluster:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ prDetails.FundCode }} - {{prDetails.SpecificFundSourceDescription }}</v-subheader>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold"
                >Office/ Section:</v-subheader
              >
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ prDetails.OfficeDescription }}</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">PR No.:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{
                prData.isDBM == 0 ? prData.PRCODE : prData.PRCODETemp
              }}</v-subheader>
            </v-col>
          </v-row>

          <v-row no-gutters class="mb-5">
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold"
                >Requested By:</v-subheader
              >
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{prData.RequestedBy}}</v-subheader>
            </v-col>
            
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Date:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ prData.DateCreated }}</v-subheader>
            </v-col>
          </v-row>
          <v-row no-gutters class="mb-5">
            <v-col cols="12" md="3">
              <v-subheader class="font-weight-bold">Purpose:</v-subheader>
            </v-col>
            <v-col cols="12" md="9">
              <v-subheader>{{prData.PrPurpose}}</v-subheader>
            </v-col>
          </v-row>
        </div>

        <v-row>
          <v-col lg="12" class="py-4">
            <v-card class="">
              <v-data-table
                :loading="loading"
                :headers="headers"
                :items="itemList"
                :options.sync="options"
                :server-items-length="totalCount"
                :footer-props="footerProps"
                class="elevation-1"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <span class="green--text text--darken-2"
                      >List Of Items</span
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                      color="secondary"
                      outlined
                      class="mr-2"
                      small
                      dark
                      @click="editPR()"
                      v-if="prData.PSUStatusID != 7 && prData.UserID == $store.state.user.UserID"
                    >
                    <v-icon class="mr-1">mdi-pencil</v-icon>
                      Edit PR
                    </v-btn>
                    <v-btn
                      color="secondary"
                      outlined
                      class="mr-2"
                      small
                      dark
                      @click="editPR()"
                      v-if="$store.state.user.roleID == 5"
                    >
                    <v-icon class="mr-1">mdi-pencil</v-icon>
                      Edit PR
                    </v-btn>
                    <vue-json-to-csv 
                    :json-data="itemListForCSV"
                    :labels="{ 
                      ItemName: { title: 'Item Name' },
                      itemDescription: { title: 'Item Description' },
                      Qty: { title: 'Quantity' },
                      UnitMeasureDescription: { title: 'Unit Measure' },
                      UnitCost: { title: 'Unit Cost' },
                      }"
                    :csv-title="prData.PRCODE || prData.PRCODETemp">
                      <v-btn
                        outlined
                        small
                        color="green darken-2"
                        dark
                        class="mr-2"
                        :loading="loadingCSV"
                      >
                        <v-icon class="mr-1">mdi-microsoft-excel</v-icon>
                        CSV
                      </v-btn>
                    </vue-json-to-csv>
                    <vue-json-to-csv
                    v-if="prData.isParent"
                    :json-data="summaryForCSV"
                    :labels="{ 
                      Office: { title: 'Office' },
                      EndUser: { title: 'End User' },
                      BudgetCode: { title: 'BudgetCode' },
                      Unit: { title: 'Unit' },
                      ItemName: { title: 'Item Name' },
                      ItemDescription: { title: 'Item Description' },
                      Qty: { title: 'Qty' },
                      UnitCost: { title: 'Unit Cost' },
                      total: { title: 'Total' },
                      }"
                    :csv-title="prData.PRCODE || prData.PRCODETemp">
                    <v-btn
                        small
                        color="green darken-2"
                        dark
                        class="mr-2"
                        :loading="loadingSummary"
                      >
                        <v-icon class="mr-1">mdi-microsoft-excel</v-icon>
                        Summary
                      </v-btn>
                    </vue-json-to-csv>
                    <v-btn
                      outlined
                      color="red darken-2"
                      dark
                      small
                      @click="print(prData)"
                      class="mr-2"
                    >
                      <v-icon>print</v-icon>
                      Print
                    </v-btn>
                    <v-btn
                      color="red darken-2"
                      class="white--text"
                      small
                      dark
                      @click="unpublish(prData.PRID)"
                      v-if="
                        $store.state.user.roleID == 5 && unpublishBtn
                      "
                    >
                      UNPUBLISH
                    </v-btn>
                    <v-btn
                      color="green darken-2"
                      dark
                      outlined
                      elevation="2"
                      v-if="
                        $store.state.user.roleID == 9 ||
                        $store.state.user.roleID == 8
                      "
                      small
                      class="mr-2"
                      @click="approveItem(prData)"
                    >
                      Approve
                    </v-btn>

                    <v-btn
                      color="red darken-2"
                      dark
                      outlined
                      elevation="2"
                      class="mr-2"
                      small
                      v-if="
                        $store.state.user.roleID == 9 ||
                        $store.state.user.roleID == 8
                      "
                      @click="pendingItem(prData)"
                    >
                      Pending
                    </v-btn>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.UnitCost`]="{ item }">
                  <span class="text-no-wrap">₱ {{ formatPrice(item.UnitCost || 0) }}</span>
                </template>
                <template v-slot:[`item.Amount`]="{ item }">
                  <span class="text-no-wrap">₱ {{formatPrice(parseFloat(item.UnitCost) * parseFloat(item.Qty))}}</span>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="pb-10">
            <v-data-table
              :headers="headers3"
              :items="filelist_uploaded"
              :options.sync="options2"
              :loading="loading2"
              :footer-props="footerProps"
              class="elevation-1 pt-2"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <h3>Attachments</h3>
                  <v-spacer></v-spacer>
                  <!-- <v-text-field
                    style="max-width: 300px"
                    v-model="searchText"
                    append-icon="mdi-magnify"
                    placeholder="Search attachment"
                    color="green darken-2"
                    class="mr-2 mb-2"
                    dense
                    hide-details
                  ></v-text-field> -->
                </v-toolbar>
              </template>
              <template v-slot:[`item.filename`]="{ item }">
                <span class="text-no-wrap">{{item.name}}</span>
              </template>
              <template v-slot:[`item.size`]="{ item }">
                <span v-if="item.size < 1024">
                  {{formatQty(parseFloat(item.size).toFixed(2))}} byte(s)
                </span>
                <span v-else-if="item.size < 1048576">
                  {{(formatQty(parseFloat(item.size) / 1024).toFixed(2))}} KB(s)
                </span>
                <span v-else>
                  {{(formatQty(parseFloat(item.size) / 1048576).toFixed(2))}} MB(s)
                </span>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                  color="blue darken-2"
                  dark
                  outlined
                  elevation="2"
                  x-small
                  @click="preview_old(item)"
                >
                  View
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="pb-10">
            <v-data-table
              :headers="headers2"
              :items="poList"
              :options.sync="options2"
              :server-items-length="totalCount2"
              :loading="loading2"
              :footer-props="footerProps"
              class="elevation-1 pt-2"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <h3>Related Purchase Orders</h3>
                  <v-spacer></v-spacer>
                  <v-text-field
                    style="max-width: 300px"
                    v-model="searchText"
                    append-icon="mdi-magnify"
                    placeholder="Search PO number"
                    color="green darken-2"
                    class="mr-2 mb-2"
                    dense
                    hide-details
                  ></v-text-field>
                </v-toolbar>
              </template>
              <template v-slot:[`item.GrandTotal`]="{ item }">
                <span class="text-no-wrap">₱ {{ formatPrice(item.GrandTotal || 0) }}</span>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                  color="blue darken-2"
                  dark
                  outlined
                  elevation="2"
                  small
                  @click="viewPO(item)"
                >
                  View
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        </v-card-text>
      </v-card>
       <v-btn fab dark large color="header" @click="viewRemarks()" fixed left bottom>
        <v-badge
          color="red darken-2"
          :content="unseenMsgs"
          :value="unseenMsgs"
        >
          <v-icon dark>mdi-email</v-icon>
        </v-badge>
    </v-btn>
    </v-dialog>
    <v-dialog v-model="dialogPending" persistent max-width="450px">
      <v-card>
        <v-card-title>
          <span class="headline">Pending Purchase Request</span>
          <v-spacer></v-spacer>
          <v-btn icon dark color="secondary2" @click="dialogPending = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-form @submit.prevent ref="formRef">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    color="green"
                    dense
                    v-model="remarks"
                    :rules="[formRules.required]"
                    label="Remarks"
                    counter
                    maxlength="500"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogPending = false">
            Cancel
          </v-btn>
          <v-btn color="green darken-1" text @click="confirmReturn()">
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogApprove" max-width="500px">
      <v-card>
        <v-card-title class="text-h6"
          >Are you sure you want to approve this PR?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-2" text @click="dialogApprove = false"
            >Cancel</v-btn
          >
          <v-btn color="green darken-2" @click="confirmApprove()" text
            >Yes</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
        <v-dialog v-model="dialogConfirm" max-width="500px">
      <v-card>
        <v-card-actions>
          <v-row justify="center">
            <v-col align="center" cols="12">
              <v-icon dense x-large color="red darken-2" class="mt-4"
                >mdi-help-circle-outline</v-icon
              >
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-title class="text-h6 d-flex justify-center"
          >Do you want to Unpublish this PR?</v-card-title
        >
        <v-card-actions class="mt-4">
          <v-row justify="center">
            <v-col align="center" cols="6">
              <v-spacer></v-spacer>
              <v-btn color="red darken-2" text @click="dialogConfirm = false"
                >Cancel</v-btn
              >
            </v-col>
            <v-col align="center" cols="6">
              <v-spacer></v-spacer>
              <v-btn color="green darken-2" text @click="confirmUnpublish()"
                >Continue</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

      <v-dialog
        v-model="EditPRDialog"
        persistent
        max-width="1200px"
        scrollable
      >
        <v-card>
          <v-card-title>
            <span class="">Edit Purchase Request</span>
            <v-spacer></v-spacer>
             <v-btn icon @click="EditPRDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
          </v-card-title>
          <v-card-text style="max-height: 900px">
            <v-container>
              <v-form @submit.prevent ref="FormrefEditPR" enctype="multipart/form-data">
              <v-row>
              <v-col cols="12" v-if="$store.state.user.UserID == prData.UserID">
                <v-text-field
                    v-model="formData.PrPurpose"
                    color="green darken-2"
                    :rules="[formRules.required]"
                    dense
                    outlined
                    label="Purpose"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <div class="flex w-full h-screen items-center justify-center text-center">
                  <div class="pa-12 dd-container" @dragover="dragover" @dragleave="dragleave" @drop="drop">
                    <input type="file" multiple name="fields[assetsFieldHandle][]" id="assetsFieldHandle" @change="onChange" ref="file" accept=".pdf"/>
                    <label for="assetsFieldHandle">
                      <div>
                        Drag and drop your attachments 
                        or <span class="text-decoration-underline font-weight-bold">click here</span> to upload the files
                      </div>
                    </label>
                    <div class="mt-4 text-start" v-if="filelist.length > 0">
                        <v-list
                          subheader
                          three-line
                        >
                          <v-subheader>File List:</v-subheader>

                          <v-list-item v-for="file in filelist" :key="file.name">
                            <v-list-item-content>
                              <v-list-item-title>{{file.name}}</v-list-item-title>
                              <v-list-item-subtitle>
                                <span v-if="file.size < 1024">
                                  {{formatQty(parseFloat(file.size).toFixed(2))}} byte(s)
                                </span>
                                <span v-else-if="file.size < 1048576">
                                  {{(formatQty(parseFloat(file.size) / 1024).toFixed(2))}} KB(s)
                                </span>
                                <span v-else>
                                  {{(formatQty(parseFloat(file.size) / 1048576).toFixed(2))}} MB(s)
                                </span>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <span class="text-no-wrap">
                                <v-btn color="grey" class="mr-1" small dark @click="preview(file)">
                                  view
                                </v-btn>
                                <v-btn color="red" small dark @click="remove(filelist.indexOf(file))">
                                  remove
                                </v-btn>
                              </span>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="6">
                <div class="flex w-full h-screen items-center justify-center text-center">
                  <div class="pa-12 dd-container2">
                    <div class="text-start" v-if="filelist_uploaded.length > 0">
                        <v-list
                          subheader
                          three-line
                        >
                          <v-subheader>Uploaded File List:</v-subheader>

                          <v-list-item v-for="file in filelist_uploaded" :key="file.name">
                            <v-list-item-content>
                              <v-list-item-title>{{file.name}}</v-list-item-title>
                              <v-list-item-subtitle>
                                <span v-if="file.size < 1024">
                                  {{formatQty(parseFloat(file.size).toFixed(2))}} byte(s)
                                </span>
                                <span v-else-if="file.size < 1048576">
                                  {{(formatQty(parseFloat(file.size) / 1024).toFixed(2))}} KB(s)
                                </span>
                                <span v-else>
                                  {{(formatQty(parseFloat(file.size) / 1048576).toFixed(2))}} MB(s)
                                </span>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <span class="text-no-wrap">
                                <a :href="file.filepath"> <v-btn color="grey" class="mr-1" @click="preview_old(file)" small dark>
                                  view
                                </v-btn></a>
                              <v-btn color="red" small dark @click="remove_old(filelist_uploaded.indexOf(file))">
                                remove
                              </v-btn>
                              </span>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                    </div>
                    <div v-if="filelist_uploaded.length == 0" class="flex justify-center">
                      <i>No Attachments available</i>
                    </div>
                    <v-divider class="my-5" v-if="filelist_deleted.length > 0"></v-divider>
                    <div class="text-start" v-if="filelist_deleted.length > 0">
                        <v-list
                          subheader
                          three-line
                        >
                          <v-subheader>Deleted File List:</v-subheader>

                          <v-list-item v-for="file in filelist_deleted" :key="file.name">
                            <v-list-item-content>
                              <v-list-item-title>{{file.name}}</v-list-item-title>
                              <v-list-item-subtitle>
                                <span v-if="file.size < 1024">
                                  {{formatQty(parseFloat(file.size).toFixed(2))}} byte(s)
                                </span>
                                <span v-else-if="file.size < 1048576">
                                  {{(formatQty(parseFloat(file.size) / 1024).toFixed(2))}} KB(s)
                                </span>
                                <span v-else>
                                  {{(formatQty(parseFloat(file.size) / 1048576).toFixed(2))}} MB(s)
                                </span>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-btn color="red" outlined small dark @click="revert_old(filelist_deleted.indexOf(file))">
                                revert
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12">
              <v-data-table
                :loading="loading"
                :headers="headers_edit"
                :items="itemList_edit"
                class="elevation-2"
                disable-pagination
                hide-default-footer
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <span class="green--text text--darken-2"
                      >List Of Items</span
                    >
                    <v-spacer></v-spacer>
                  </v-toolbar>
                </template>
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="item in items" :key="item.ppmpItemID">
                      <td>{{ item.ItemName }}</td>
                      <td>
                        <v-edit-dialog :return-value.sync="item.itemDescription" large>
                          {{ item.itemDescription  }}
                          <template v-slot:input>
                            <v-textarea v-model="item.itemDescription"> </v-textarea>
                          </template>
                        </v-edit-dialog>
                      </td>
                      <td>{{ item.Qty }}</td>
                      <td>{{ item.UnitMeasureDescription }}</td>
                      <td align="end">₱ {{ formatPrice(item.UnitCost) }}</td>
                      <td align="end">
                        ₱
                        {{
                          formatPrice(
                            parseFloat(item.UnitCost) * parseFloat(item.Qty)
                          )
                        }}
                      </td>
                      <td align="center">
                        <!-- <v-btn
                          color="blue darken-3"
                          outlined
                          class="mr-2"
                          x-small
                          dark
                          @click="editItem()"
                          v-if="prData.isApproved == 0 && prData.UserID == $store.state.user.UserID"
                        >
                        <v-icon class="mr-1" x-small>mdi-pencil</v-icon>
                          Edit
                        </v-btn> -->
                        <v-btn
                          color="red darken-3"
                          class="mr-2 white--text"
                          x-small
                          @click="deleteItem(item)"
                          :disabled="itemList.length <= 1"
                          v-if="prData.isApproved == 0 && prData.UserID == $store.state.user.UserID && item.PRstatus != 4 && item.isRevised == 0"
                        >
                        <v-icon class="mr-1" x-small>mdi-delete</v-icon>
                          Delete
                        </v-btn>
                        <v-btn
                          color="red darken-3"
                          class="mr-2 white--text"
                          x-small
                          @click="deleteItem(item)"
                          :disabled="itemList.length <= 1"
                          v-else-if="$store.state.user.roleID == 5 && item.PRstatus != 4 && item.isRevised == 0"
                        >
                        <v-icon class="mr-1" x-small>mdi-delete</v-icon>
                          Delete
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
                </v-col>

              <v-col cols="12" v-if="itemList_delete.length > 0">
              <v-data-table
                :loading="loading"
                :headers="headers_edit"
                :items="itemList_delete"
                class="elevation-2"
                disable-pagination
                hide-default-footer
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <span class="red--text text--darken-2"
                      >List Of Deleted Items</span
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                      color="red darken-2"
                      class="mr-2 white--text"
                      small
                      @click="restoreAllItems()"
                      v-if="prData.isApproved == 0 && prData.UserID == $store.state.user.UserID"
                      :disabled="itemList_delete.length <= 1"
                    >
                    <v-icon class="mr-1">mdi-delete-restore</v-icon>
                      Restore All
                    </v-btn>
                    <v-btn
                      color="red darken-2"
                      class="mr-2 white--text"
                      small
                      @click="restoreAllItems()"
                      v-else-if="$store.state.user.roleID == 5"
                      :disabled="itemList_delete.length <= 1"
                    >
                    <v-icon class="mr-1">mdi-delete-restore</v-icon>
                      Restore All
                    </v-btn>
                  </v-toolbar>
                </template>
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="item in items" :key="item.ppmpItemID">
                      <td>{{ item.ItemName }}</td>
                      <td>
                        <v-edit-dialog :return-value.sync="item.itemDescription" large>
                          {{ item.itemDescription  }}
                          <template v-slot:input>
                            <v-textarea v-model="item.itemDescription"> </v-textarea>
                          </template>
                        </v-edit-dialog>
                      </td>
                      <td>{{ item.Qty }}</td>
                      <td>{{ item.UnitMeasureDescription }}</td>
                      <td align="end">₱ {{ formatPrice(item.UnitCost) }}</td>
                      <td align="end">
                        ₱
                        {{
                          formatPrice(
                            parseFloat(item.UnitCost) * parseFloat(item.Qty)
                          )
                        }}
                      </td>
                      <td align="center">
                        <!-- <v-btn
                          color="blue darken-3"
                          outlined
                          class="mr-2"
                          x-small
                          dark
                          @click="editItem()"
                          v-if="prData.isApproved == 0 && prData.UserID == $store.state.user.UserID"
                        >
                        <v-icon class="mr-1" x-small>mdi-pencil</v-icon>
                          Edit
                        </v-btn> -->
                        <v-btn
                          color="red darken-3"
                          outlined
                          class="mr-2 white--text"
                          x-small
                          @click="restoreItem(item)"
                          v-if="item.PRstatus != 4 && item.isRevised == 0 && prData.UserID == $store.state.user.UserID"
                        >
                        <v-icon class="mr-1" x-small>mdi-delete-restore</v-icon>
                          Restore
                        </v-btn>
                        <v-btn
                          color="red darken-3"
                          outlined
                          class="mr-2 white--text"
                          x-small
                          @click="restoreItem(item)"
                          v-else-if="$store.state.user.roleID == 5 && item.PRstatus != 4 && item.isRevised == 0"
                        >
                        <v-icon class="mr-1" x-small>mdi-delete-restore</v-icon>
                          Restore
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
                </v-col>
              </v-row> 
                </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions class="pb-5">
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              outlined
              @click="EditPRDialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="green darken-2 white--text"
              @click="updatePR()"
            >
            <v-icon class="mr-1">mdi-check-circle-outline</v-icon>
              Update
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
        <v-dialog
          v-model="confirmationDialog"
          persistent
          max-width="450px"
        >
          <v-card>
            <v-card-title>
              <span class="">Confirmation</span>
            </v-card-title>
            <v-card-text>
              {{confirmationMessage}}
              <div v-if="itemList_delete.length > 0 && confirmType == 1">
              <div class="mt-3">
              <b>Deleted Items: </b> 
              <ul>
                <li v-for="(item, index) in itemList_delete" :key="index">
                  {{ item.ItemName}}
                </li>
              </ul>
            </div>
            <div class="mt-3" v-if="itemList_delete.length > 0">
            <i><strong>Note: </strong>All deleted items will be permanently removed to this Purchase Request.</i>
            </div>
            </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="red darken-1"
                outlined
                @click="confirmationDialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="green darken-2"
                class="white--text"
                @click="confirmUpdatePR()"
                v-if="confirmType == 1"
              >
              <v-icon>mdi-check-circle-outline</v-icon>
                Update
              </v-btn>
              <v-btn
                color="red darken-2"
                class="white--text"
                @click="confirmDeleteItem()"
                v-if="confirmType == 2"
              >
              <v-icon>mdi-delete</v-icon>
                Delete
              </v-btn>
              <v-btn
                color="red darken-2"
                class="white--text"
                @click="confirmRestoreItem()"
                v-if="confirmType == 3"
              >
              <v-icon>mdi-restore</v-icon>
                Restore
              </v-btn>
              <v-btn
                color="red darken-2"
                class="white--text"
                @click="confirmRestoreAllItems()"
                v-if="confirmType == 4"
              >
              <v-icon>mdi-restore</v-icon>
                Restore All
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <PSURemarks :data="prRemarksData" />
        <POModal :poData="poItem" />
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>
    
    <script>
export default {
  components: {
      PSURemarks: () => import("./PSURemarks.vue"),
      POModal: () => import("../PO-Components/PSUViewPOItem.vue"),
    },
  props: {
    prData: {},
  },
  data: () => ({
    prDetails: [],
    remarks: null,
    headers: [
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "itemDescription", sortable: false },
      { text: "Quantity", value: "Qty", sortable: false },
      { text: "Unit Meas.", value: "UnitMeasureDescription", sortable: false },
      { text: "Unit Cost", value: "UnitCost", sortable: false },
      { text: "Amount", value: "Amount", sortable: false },
    ],
     headers_edit: [
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "itemDescription", sortable: false },
      { text: "Quantity", value: "Qty", sortable: false },
      { text: "Unit Meas.", value: "UnitMeasureDescription", sortable: false },
      { text: "Unit Cost", value: "UnitCost", align: 'end', sortable: false },
      { text: "Amount", value: "amount", align: 'end', sortable: false },
      { text: "Action", value: "action", align: 'center', sortable: false },
    ],
    headers_delete: [
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "itemDescription", sortable: false },
      { text: "Quantity", value: "Qty", sortable: false },
      { text: "Unit Meas.", value: "UnitMeasureDescription", sortable: false },
      { text: "Unit Cost", value: "UnitCost", align: 'end', sortable: false },
      { text: "Amount", value: "amount", align: 'end', sortable: false },
      { text: "Action", value: "action", align: 'center', sortable: false },
    ],
    loading: false,
    prViewItemDialog: false,
    footerProps: { "items-per-page-options": [5, 10, 20, 50, 100, 500] },
    totalCount: 0,
    options: {},
    isLoading: false,
    itemList: [],
    itemList_edit: [],
    itemList_delete: [],
    item: null,
    dialogPending: false,
    dialogApprove: false,
    dialogConfirm: false,
    unpublishBtn: false,
    toUnpublishPRID: null,
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Added!",
      message: "",
      top: 10,
    },
    loadingCSV: false,
    itemListForCSV: [],
    EditPRDialog: false,
    confirmationDialog: false,
    dialogConfirmUpdate: false,
    itemToDelete: [],
    itemToRestore: [],
    confirmationMessage: '',
    confirmType: null,
    prRemarksData: [],
    unseenMsgs: 0,
    poItem: [],
    poList: [],
    footerProps2: { "items-per-page-options": [5, 10, 20, 50, 100, 500] },
    totalCount2: 0,
    loading2: true,
    searchText: '',
    options2: {},
    headers2: [
      {
        text: "PO Number",
        value: "POCode",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Supplier",
        value: "CompanyName",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Supplier Address",
        value: "CompanyAddress",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Grand Total",
        value: "GrandTotal",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Actions",
        value: "actions",
        align: "center",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
    ],
    headers3: [
      {
        text: "FileName",
        value: "filename",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Size",
        value: "size",
        align: "center",
        sortable: false,
        width: 150,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Actions",
        value: "actions",
        align: "center",
        sortable: false,
        width: 150,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
    ],
    formData: {
      PrPurpose: null,
    },
    filelist: [],
    filelist_uploaded: [],
    filelist_deleted: [],
    loadingSummary: true,
    summaryForCSV: [],
  }),
  watch: {
    options: {
      handler() {
        if (this.totalCount > 0) {
          this.getItems();
        }
      },
      deep: true,
    },
    options2: {
      handler() {
        this.getPOs();
      },
      deep: true,
    },
    searchText: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.getPOs();
      }, 600);
    },
    prData: {
      handler(data) {
        if (data.PRID) {
          this.prViewItemDialog = true;
          this.getItems();
          this.getDataForCSV();
          this.getUnseenMsgs();
          this.getPOs();
          this.getAttachmentsByPRID();
          if(data.isParent == 1){
            this.getSummaryForCSV();
          }
        }
      },
      deep: true,
    },
  },
  mounted() {
        this.eventHub.$on('closePSURemarks', ()=>{
          this.prRemarksData = [];
         this.getUnseenMsgs();
        });
        this.eventHub.$on("closePOItemodal", () => {
          this.getPOs();
        });
  },
  beforeDestroy() {
         this.eventHub.$off('closePSURemarks');
         this.eventHub.$off("closePOItemodal");
    },
  methods: {
    viewPO(item) {
      this.poItem = item;
    },
    viewRemarks(){
      this.prRemarksData = {
        id: this.prData.PRID,
        DocType: 2,
        RemarksID: this.prData.RemarksID
      };
      if(this.unseenMsgs > 0){
        let data = new FormData();
        data.append("RemarksID", this.prData.RemarksID);
        this.axiosCall("/remarks/seen", "POST", data).then((res) => {
          console.log(res)
          this.unseenMsgs = 0
        });
      }
    },
    getPOs() {
      this.loading2 = true;
      let data = new FormData();
      if (this.options2.page) {
        data.append("page", this.options2.page);
        data.append("itemsPerPage", this.options2.itemsPerPage);
      } else {
        data.append("page", 1);
        data.append("itemsPerPage", 10);
      }
      // data.append("tab", this.tab);
      data.append("PRID", this.prData.PRID);
      data.append("searchText", this.searchText);
      // data.append("OfficeID", 0);
      // data.append("DeliveryUnitID", 0);
      // data.append("PurposeID", 0);
      // data.append("SpecificFundSourceID", 0);
      this.axiosCall("/pr/relatedPO", "POST", data).then((res) => {
        this.loading2 = false;
        this.poList = res.data.data.result;
        this.totalCount2 = res.data.data.total;
        if (this.totalCount2 <= 10) {
          this.options2.page = 1;
        }
      });
    },
    getUnseenMsgs(){
      this.unseenMsgs = 0;
        let data = new FormData();
        data.append("RemarksID", this.prData.RemarksID);
        this.axiosCall("/getUnseenMsgs", "POST", data).then((res) => {
        this.unseenMsgs = res.data.data
      });
    },
    approveItem(data) {
      this.item = data;
      this.dialogApprove = true;
    },
    pendingItem(data) {
      this.item = data;
      this.dialogPending = true;
    },
    confirmReturn() {
      if (this.$refs.formRef.validate()) {
        let data = new FormData();
        data.append("Remarks", this.remarks);
        data.append("PRID", this.item.PRID);
        this.axiosCall("/signatories/pr/pending", "POST", data).then((res) => {
          console.log(res);
          this.clickCloseMainDialog();
        });
      }
    },
    confirmApprove() {
      let data = new FormData();
      data.append("PRID", this.item.PRID);
      this.axiosCall("/signatories/pr/approve", "POST", data).then((res) => {
        console.log(res);
        this.clickCloseMainDialog();
      });
    },
    clickCloseMainDialog() {
      this.dialogPending = false;
      this.dialogApprove = false;
      this.totalCount = 0;
      this.prViewItemDialog = false;
      this.remarksData = [];
      this.itemList_delete = [];
      // this.searchText = '';
      // this.options2 = [];
      // this.poList = [];
      // this.totalCount2 = 0;
      this.filelist_uploaded = [];
      this.filelist_deleted = [];
      this.eventHub.$emit("closePRItemodal", false);
      this.eventHub.$emit("closePRItemodal1", false);
    },
    print(data) {
      window.open(this.apiUrl + "/pr/report/" + data.PRID);
    },
    getItems() {
      this.unpublishBtn = false;
      let data = new FormData();
      if (this.options.page) {
        data.append("page", this.options.page);
        data.append("itemsPerPage", this.options.itemsPerPage);
      } else {
        data.append("page", 1);
        data.append("itemsPerPage", 10);
      }
      data.append("PRID", this.prData.PRID);
      this.axiosCall("/pr/ViewPRItems", "POST", data).then((res) => {
        this.loading = false;
        this.prDetails = res.data.data.details;
        this.itemList = res.data.data.result;
        this.itemList_edit = res.data.data.result;
        this.totalCount = res.data.data.total;
        if (this.totalCount <= 10) {
          this.options.page = 1;
        }
        // this.ppmpUsedAmount = parseFloat((res.data.data.useAmount).toFixed(2));
        this.ableToUnpublish();
      });
    },
    unpublish(PRID) {
      this.dialogConfirm = true;
      this.toUnpublishPRID = PRID;
    },
    ableToUnpublish() {
      this.unpublishBtn = false;
        this.itemList.forEach(item => {
          if (item.PRID != 0 && item.PRstatus != 4) {
          this.unpublishBtn = true;
        }
        });
    },
    confirmUnpublish(){
      let data = new FormData();
      data.append("PRID", this.toUnpublishPRID);
      this.axiosCall("/pr/unpublish", "POST", data).then((res) => {
        if(res.data.status) {
            this.toUnpublishPPMPID = null;
            this.dialogConfirmUnpublish = false;
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = 'success';
            this.fadeAwayMessage.message = 'Purchase Request successfully unpublished!';
            this.fadeAwayMessage.header = 'System Message';
            this.dialogConfirm = false;
            this.prViewItemDialog = false;
            }else {
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = 'error';
            this.fadeAwayMessage.message = res.data.message;
            this.fadeAwayMessage.header = 'System Message';
            }
      });
    },
    getDataForCSV(){
      this.loadingCSV = true;
      let data = new FormData();
      data.append("PRID", this.prData.PRID);
      this.axiosCall("/csv/getItemsByPRID", "POST", data).then((res) => {
        this.loadingCSV = false;
        this.itemListForCSV = res.data.data.result;
      });
    },
    getSummaryForCSV(){
      this.loadingSummary = true;
      let data = new FormData();
      data.append("PRID", this.prData.PRID);
      this.axiosCall("/csv/getSummaryByPRID", "POST", data).then((res) => {
        this.loadingSummary = false;
        this.summaryForCSV = res.data.data;
      });
    },
    editPR(){
      this.formData.PrPurpose = this.prData.PrPurpose;
      this.EditPRDialog = true;
    },
    deleteItem(data){
      this.confirmType = 2;
      this.confirmationMessage = "Are you sure you want to delete this Item?"
      this.itemToDelete = data;
      this.confirmationDialog = true;
    },
    restoreItem(data){
      this.confirmType = 3;
      this.confirmationMessage = "Are you sure you want to restore this Item?"
      this.itemToRestore = data;
      this.confirmationDialog = true;
    },
    restoreAllItems(){
      this.confirmType = 4;
      this.confirmationMessage = "Are you sure you want to restore all items?"
      this.confirmationDialog = true;
    },
    updatePR(){
      this.confirmType = 1;
      this.confirmationMessage = "Are you sure you want to update this PR?"
      this.confirmationDialog = true;
    },
    confirmUpdatePR(){
      let data = new FormData();
      data.append("PRID", this.prData.PRID);
      data.append("Purpose", this.formData.PrPurpose);
      data.append('List', JSON.stringify(this.itemList_edit));
      data.append('deleteList', JSON.stringify(this.itemList_delete));
      this.axiosCall("/pr/UpdatePR", "POST", data).then((res) => {
        if(res.data.status) {
            this.confirmationDialog = false;
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = 'success';
            this.fadeAwayMessage.message = 'Purchase Request successfully updated!';
            this.fadeAwayMessage.header = 'System Message';
            this.EditPRDialog = false;
            this.itemList_delete = [];
            this.uploadFiles();
            this.getItems();
            this.getAttachmentsByPRID();
            this.filelist_deleted = [];
            this.filelist = [];
            }else {
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = 'error';
            this.fadeAwayMessage.message = res.data.message;
            this.fadeAwayMessage.header = 'System Message';
            }
      });
    },
    uploadFiles(){
      let data = new FormData();
      this.filelist.forEach((item, i) => {
        if(!item.PRID){
        data.append('files['+i+']', item || '');
        }
      });
      data.append("PRID", this.prData.PRID);
      if(this.filelist_deleted.length > 0){
      data.append("files_deleted", JSON.stringify(this.filelist_deleted));
      }
      this.axiosCall(
              '/upload/pr/attachment',
              'POST',
              data
          )
          .then((res) => {
              if (res.data.status){
                this.getAttachmentsByPRID();
              }
              else{
              this.fadeAwayMessage.show = true;
              this.fadeAwayMessage.type = 'error';
              this.fadeAwayMessage.message = res.data.message;
              this.fadeAwayMessage.header = 'System Message';
              }
          })
    },
    confirmDeleteItem(){
      let index = this.itemList_edit.indexOf(this.itemToDelete);
      if (index > -1) {
        this.itemList_edit.splice(index, 1);
        this.itemList_delete.push(this.itemToDelete);

        this.confirmationDialog = false;
        this.fadeAwayMessage.show = true;
        this.fadeAwayMessage.type = "success";
        this.fadeAwayMessage.message = "Item Successfully Added to the trash!";
        this.fadeAwayMessage.header = "System Message";
      }
    },
    confirmRestoreItem(){
      let index = this.itemList_delete.indexOf(this.itemToRestore);
      if (index > -1) {
        this.itemList_delete.splice(index, 1);
        this.itemList_edit.push(this.itemToRestore);

        this.confirmationDialog = false;
        this.fadeAwayMessage.show = true;
        this.fadeAwayMessage.type = "success";
        this.fadeAwayMessage.message = "Item Successfully restored!";
        this.fadeAwayMessage.header = "System Message";
      }
    },
    confirmRestoreAllItems(){
       this.itemList_delete.forEach(item => {
        this.itemList_edit.push(item);
       });
        this.itemList_delete = [];
        this.confirmationDialog = false;
        this.fadeAwayMessage.show = true;
        this.fadeAwayMessage.type = "success";
        this.fadeAwayMessage.message = "All Items Successfully restored!";
        this.fadeAwayMessage.header = "System Message";
    },
    onChange() {
      if((this.$refs.file.files.length + this.filelist_uploaded.length) > 5){
        this.fadeAwayMessage.show = true;
        this.fadeAwayMessage.type = "error";
        this.fadeAwayMessage.header = "System Message";
        this.fadeAwayMessage.message = "Maximum of 5 PDF files are allowed.";
        this.filelist = [];
      }
      else{
        this.filelist = [...this.$refs.file.files];
      }
      },
      preview(file) {
        window.open(URL.createObjectURL(file));
      },
      preview_old(file) {
        // console.log(file)
        window.open(this.apiUrl + '/attachment/' + file.id, '_blank');
      },
      remove(i) {
        this.filelist.splice(i, 1);
      },
      remove_old(i){
        this.filelist_deleted.push(this.filelist_uploaded[i]);
        this.filelist_uploaded.splice(i, 1);
      },
      revert_old(i){
        this.filelist_uploaded.push(this.filelist_deleted[i]);
        this.filelist_deleted.splice(i, 1);
      },
      dragover(event) {
        event.preventDefault();
        if (!event.currentTarget.classList.contains('bg-green-300')) {
          event.currentTarget.classList.remove('bg-gray-100');
          event.currentTarget.classList.add('bg-green-300');
        }
      },
      dragleave(event) {
        event.currentTarget.classList.add('bg-gray-100');
        event.currentTarget.classList.remove('bg-green-300');
      },
      drop(event) {
        event.preventDefault();
        this.$refs.file.files = event.dataTransfer.files;
        if((event.dataTransfer.files.length + this.filelist_uploaded.length) > 5){
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "error";
          this.fadeAwayMessage.header = "System Message";
          this.fadeAwayMessage.message = "Maximum of 5 PDF files are allowed.";
        }
        else{
          this.onChange();
        }
        event.currentTarget.classList.add('bg-gray-100');
        event.currentTarget.classList.remove('bg-green-300');
      },
      getAttachmentsByPRID(){
      let data = new FormData();
      data.append("PRID", this.prData.PRID);
      this.axiosCall(
              '/attachment/getByPRID',
              'POST',
              data
          )
          .then((res) => {
              if (res.data.status){
                this.filelist_uploaded = res.data.data
              }
              else{
              this.fadeAwayMessage.show = true;
              this.fadeAwayMessage.type = 'error';
              this.fadeAwayMessage.message = res.data.message;
              this.fadeAwayMessage.header = 'System Message';
              }
          })
    },
  },
};
</script>
        
    <style scoped>
.v-dialog--fullscreen,
.prModal {
  overflow-x: hidden !important;
}
.dividerClass {
   border-width: 1px !important;
}
.dd-container {
  background-color: white !important;
  border: 3px dashed green !important;
}
.dd-container2 {
  background-color: white !important;
  border: 2px solid green !important;
}
</style>